<template>
  <footer class="footer-container">
    <div class="footer">
      <div class="footer-top">
        <n-space>
          <FTListItem
            title="核心产品"
            :list="[
              'OCR病历识别',
              '病历分类',
              '检验报告内容提取',
              '体检报告内容提取',
              '核保内容提取',
              '理赔内容提取'
            ]"
          />
          <FTListItem
            title="行业解决方案"
            :list="[
              '理赔RPA',
              '核保RPA',
              '医疗单位归一化',
              '疾病名称归一化',
              '理赔风控',
              '既往史筛查'
            ]"
          />
          <FTListItem
            title="关于司普"
            :list="['公司简介', '加入司普', '联系我们', '安全中心']"
          />
          <div class="last-ft-list">
            <FTListItem
              title="司普"
              :list="[
                '联系电话：173 8875 5868  张先生',
                [
                  {
                    content: '公司地址：'
                  },
                  {
                    content:
                      '深圳市福田区梅林街道中康路136号深圳新一代产业园5栋6层018',
                    style: 'width: 260px'
                  }
                ]
              ]"
            />
          </div>
        </n-space>
      </div>
      <n-divider style="margin-bottom: 10px"></n-divider>
      <div class="text-xs">
        <small class="block"
          >深圳市司普科技有限公司 保留所有权利
          <a href="https://beian.miit.gov.cn" target="_blank"
            >粤ICP备2023079211号-2</a
          >
          - 单位门户网站 法律声明
          <img
            src="@/assets/img/110.png"
            width="14"
            height="14"
            class="icon-110 inline-block"
          />
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002002479"
            rel="noreferrer"
            target="_blank"
            class="inline-block"
            >粤公网安备44030002002479</a
          >
        </small>
        <small class="block"
          > 深公网安备
          3101060245673171号深圳市互联网违法和不良信息举报中心 隐私政策</small
        >
        <!-- <small class="block">
          
        </small> -->
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
$border-color: #7e8baf;
.footer-container {
  background: linear-gradient(to right bottom, #184aaa, #2a78fa);
  height: 392px;
  padding: 45px 45px 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
  box-sizing: border-box;
  .footer {
    width: 1151px;
    margin: auto;
  }
  .last-ft-list {
    padding-left: 24px;
    border-left: 1px solid $border-color;
  }
  .icon-110 {
    margin-right: 2px;
    vertical-align: sub;
    margin-left: 14px;
  }
}
:deep(.n-divider .n-divider__line) {
  background-color: $border-color;
}
</style>
